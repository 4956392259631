import React from "react"
import Image from "../components/image"

const IndexPage = () => (
  <div
    style={{width: 800}}>
    <Image />
  </div>

)

export default IndexPage
